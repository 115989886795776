import { NgModule } from "@angular/core";
import { AutoFocusDirectiveModule } from "./auto-focus/auto-focus.module";
import { DocViewerPopupModule } from "./doc-viewer-popup/doc-viewer-popup.module";
import { EntityEditLinkModule } from "./entity-edit-link/entity-edit-link.module";
import { InputCaseModule } from "./input-case/input-case.module";
import { InputMinMaxNumberModule } from "./input-min-max-number/input-min-max-number.module";
import { MatTabHideModule } from "./mat-tab-hide/mat-tab-hide.module";
import { ResizableModule } from "./resizable/resizable.module";

@NgModule({
    declarations: [],
    imports: [],
    exports: [
        EntityEditLinkModule,
        DocViewerPopupModule,
        InputCaseModule,
        InputMinMaxNumberModule,
        MatTabHideModule,
        ResizableModule,
        AutoFocusDirectiveModule,
    ],
})
export class SharedDirectivesModule {}
