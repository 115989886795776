import { QrmCellTrans } from "app/core/models/entity-models/qrmCellTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Cells */
@Name("QrmCell")
export class QrmCell extends QrmCellTrans implements IBaseEntity {
    name = "QrmCell";
    QrmCell;
    baseEditRouteSegment = "qrm-cell";
    public get id(): string {
        return this.qrmCellCE;
    }

    public errorMessage: string;
    /** Focused Target Market Segment Code */
    public ftmsCE: string;
    /** Visual Style Code */
    public visualStyleCE: string;
    /** Calendar Code */
    public calendarCE: string;
    /** Cell Capacity Cluster Quantity per Time Period */
    public cellCapacityClusterQT: number;
    /** Cell Capacity Cluster Code */
    public cellCapacityClusterCE: string;
    /** Capacity Cluster Quantity per Polca Card */
    public polcaCardCapacityClusterQT: number;
    /** Polca Card Capacity Cluster Code                     Virtual attribute */
    public polcaCardCapacityClusterCE: string;
    /** Time Quantity */
    public timeQT: number;
    /** Unit of Time Code */
    public timeUnitCE: string;
    /** Default Capacity Threshold Percentage */
    public defaultCapacityThresholdPC: number;
    /** QRM Cell Preference Sequence Number */
    public qrmCellPreferenceSQ: number;

    public polcaJS: string;
    public calIndex: number;
    /** Outsource Flag */
    public outsourceFL: string;


    public get outsourceFLBoolean(): boolean {
        if (this.outsourceFL === "Y") return true;
        return false;
    }

    public set outsourceFLBoolean(value: boolean) {
        if (value) this.outsourceFL = "Y";
        else this.outsourceFL = "N";
    }

    /** qrmCellCE qrmCellLB */
    public get caption(): string {
        return `${this.qrmCellCE} ${this.qrmCellLB}`;
    }

    public get displayWithChips() {
        return `${this.qrmCellCE} - ${this.qrmCellLB}`;
    }
    public setConfigFromHT() {
    //    if (this.qrmCellHT.includes("showNotes")) this.showNotes = true;
    //    if (this.qrmCellHT.includes("showTouch")) this.showTouch = true;
    //    if (this.qrmCellHT.includes("showProduct")) this.showProduct = true;
    //    if (this.qrmCellHT.includes("showSales")) this.showSales = true;
    //    if (this.qrmCellHT.includes("showFeedback")) this.showFeedback = true;
    //    if (this.qrmCellHT.includes("nrQs=")) {
    //        var i: number = this.qrmCellHT.indexOf("nrQs=") + 5;
    //        var l: number = this.qrmCellHT.indexOf(",", i);
    //        if (l >= 0) this.nrQs = Number(this.qrmCellHT.substring(i, l));
    //        else this.nrQs = Number(this.qrmCellHT.substring(i));
    //    }
    //    if (this.qrmCellHT.includes("headerHeight=")) {
    //        var i: number = this.qrmCellHT.indexOf("headerHeight=") + 13;
    //        var l: number = this.qrmCellHT.indexOf(",", i);
    //        if (l >= 0) this.nrHeaderHeight = Number(this.qrmCellHT.substring(i, l));
    //        else this.nrHeaderHeight = Number(this.qrmCellHT.substring(i));
    //    }
    }
    public makeJSFromConfig() {
        this.polcaJS = JSON.stringify(this.polcaDefinition);
    }
    public showTouch(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showTouch;
    }
    public showNotes(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showNotes;
    }
    public showResources(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showResources;
    }
    public showTexts(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showTexts;
    }
    public showProduct(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showProduct;
    }
    public showSales(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showSales;
    }
    public showFeedback(listNr: number): boolean {
        if (listNr == 9) return true;
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.showFeedback;
    }
    public flipFeedback(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showFeedback = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showFeedback;
    }
    public flipNotes(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showNotes = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showNotes;
    }
    public flipResources(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showResources = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showResources;
    }
    public flipTouch(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showTouch = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showTouch;
    }
    public flipTexts(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showTexts = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showTexts;
    }
    public flipSales(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showSales = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showSales;
    }
    public flipProduct(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.showProduct = !this.polcaDefinition.lists[listNr - 1].ticketLayout.showProduct;
    }
    public get nrQs(): number {
        return this.polcaDefinition.nrLists;
    }
    public set nrQs(value: number) {
        this.polcaDefinition.nrLists = value;
    }
    public headerHeight(listNr: number): string {
        if (!this.polcaDefinition) this.initPolca();
        if (listNr == 9) return this.polcaDefinition.lists[2].ticketLayout.headerHeight.toString() + "px";
        return this.polcaDefinition.lists[listNr - 1].ticketLayout.headerHeight.toString() + "px";
    }
    private nrHeaderHeight: number = 40;
    public incHeaderHeight(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.headerHeight += 5;
    }
    public decHeaderHeight(listNr: number) {
        this.polcaDefinition.lists[listNr - 1].ticketLayout.headerHeight -= 5;
        if (this.polcaDefinition.lists[listNr - 1].ticketLayout.headerHeight < 30) this.polcaDefinition.lists[listNr - 1].ticketLayout.headerHeight = 30;
    }
    //get unitCE(): string {
    //    return this.timeUnitCE;
    //}

    //set unitCE(value: string) {
    //    this.timeUnitCE = value;
    //}

    //get capacityClusterCE(): string {
    //    return this.cellCapacityClusterCE;
    //}

    //set capacityClusterCE(value: string) {
    //    this.cellCapacityClusterCE = value;
    //}
    public ftms: any;
    private _polcaConfig: object; //= this.stdPolcaLayout();
    public polcaDefinition: any;
    public initPolca() {
        if (this.polcaJS) {
            this.polcaDefinition = JSON.parse(this.polcaJS);
        }
        else if (!this.polcaDefinition && this.ftms) {
            if (!this.ftms.polcaDefinition)
                this.ftms.initPolca();
            this.polcaDefinition = this.ftms.polcaDefinition;
        }
        else if (!this.polcaDefinition && !this.ftms)
            this.polcaDefinition = this.stdPolcaDefiniton;
    }

    public get stdPolcaListDefinition(): any {
        return {
            whereTX: "",
            orderByTX: "",
            title: "",
            maxItems: 20,
            ticketLayout: this.stdPolcaTicketLayout,
        };
    }
    public get stdPolcaTicketLayout(): any {
        return {
            iconPolcCards: "check",
            iconInOperation: "settings_suggest",
            iconDone: "sports_score",
            headerHeight: 35,
            showResources: true,
            showNotes: true,
            showTouch: true,
            showProduct: true,
            showSales: true,
            showTexts: true,
            showFeedback: true,
            product: 12,
            dates: 21,
            feedback: 31,
            resources: 41,
            texts: 51,
            touch: 61,
            notes: 71,
            sales: 81,
        };
    }
    public get stdPolcaDefiniton(): any {
        var l0: any = this.stdPolcaListDefinition;
        l0.title = "Release";
        l0.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '25')";
        l0.orderByTX = "AuthorizationDT";
        l0.ticketLayout.showTouch = false;
        var l1: any = this.stdPolcaListDefinition;
        l1.title = "Preparation";
        l1.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '50')";
        l1.orderByTX = "AuthorizationDT";
        l1.ticketLayout.showTouch = false;
        var l2: any = this.stdPolcaListDefinition;
        l2.title = "Queue";
        l2.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '50')";
        l2.orderByTX = "AuthorizationDT";
        l2.ticketLayout.showTouch = false;
        var l3: any = this.stdPolcaListDefinition;
        l3.title = "In Operation";
        l3.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '60'";
        l3.orderByTX = "AuthorizationDT";
        l3.ticketLayout.showSales = false;
        var l4: any = this.stdPolcaListDefinition;
        l4.title = "Finished";
        l4.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '65'";
        l4.orderByTX = "AuthorizationDT";
        l4.ticketLayout.showResources = false;
        l4.ticketLayout.showTexts = false;
        l4.ticketLayout.showProduct = false;
        l4.ticketLayout.showFeedback = false;
        var l5: any = this.stdPolcaListDefinition;
        l5.title = "Closed";
        l5.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '70'";
        l5.orderByTX = "AuthorizationDT";
        l5.ticketLayout.showResources = false;
        l5.ticketLayout.showTexts = false;
        l5.ticketLayout.showProduct = false;
        l5.ticketLayout.showFeedback = false;
        return {
            nrLists: 2,
            lists: [l0, l1, l2, l3, l4, l5]
        };
    }

    public get polcaConfig(): object {
        if (!this._polcaConfig) {
            this._polcaConfig = this.stdPolcaConfig();
        }
        return this._polcaConfig;
    }

    private stdPolcaConfig(): object {
        return {
            defaultNrLists: 2,
            ticketLayouts: [{
                notReady: this.stdTicketLayout(30),
                ready: this.stdTicketLayout(30),
                busy: this.stdTicketLayout(),
                finished: this.stdTicketLayout(),
            }],
            oneListConfig: [this.stdListConfig(1)],
            twoListConfig: [this.stdListConfig(2), this.stdListConfig(3)]

        }
    }
    private stdListConfig(list: number = 1): object {
        if (list == 1)
            return {
                forwardDays: 1,
                backwardDays: 1,
                whereTX: "",
                sortTX: "",

            };
        else if (list == 2)
            return {
                forwardDays: 1,
                backwardDays: 1,
                whereTX: "",
                sortTX: "",
            };
        else
            return {
                forwardDays: 1,
                backwardDays: 1,
                whereTX: "",
                sortTX: "",
            };
    }
    private stdTicketLayout(touch: number = 31): object {
        return {
            headerHeight: 35,
            dates: 11,
            feedback: 21,
            touch: touch,
            product: 42,
            notes: 51,
            sales: 61
        };
    }
    public listItemLayout(): object {
        return {
            label: { property: "qrmCellLB" },
            inBrackets: { property: "tracingDT" },
            key: { property: "qrmCellCE" },
            title: { property: "qrmCellDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [{ toSlice: 200, property: "new" }],
        };
    }
}
